import React, { Component } from 'react';

import { connect } from 'react-redux';

import './styles.css';

import Header from '../../components/Header/index';

import ItemCart from '../../components/ItemCart/index';

import { Creators as CartCreator } from '../../store/ducks/cart';

import { Creators as ProductCreator } from '../../store/ducks/product';

import Currency from '../../utils/currency';

import { FaPlus, FaCheck, FaTimesCircle } from 'react-icons/fa';

import { toast } from 'react-toastify';

import Popup from 'reactjs-popup';

import api from '../../services/api';

class Cart extends Component {
    constructor(props) {
        super(props);
        
        window.scrollTo(0, 0);

        let empresa = localStorage.getItem("@comer-aqui/empresa");

        if (!empresa) {
            return;
        }


        localStorage.setItem('typeorder',this.props.cart.typeorder);

        empresa = JSON.parse(empresa);

        if (this.props.cart.typeorder == 1) {
            empresa.vlTaxaentrega = 0;
        }

        let taxaDeServico = empresa.vlTaxaentrega;

        this.state = { items: props.cart.items, empresa, taxaDeServico, show: false, existInVerificaimpressora: false, disable: false };
    }

    handleMoreItems = e => {
        this.props.removeProduct();

        e.preventDefault();


        if (this.props.cart.typeorder == 4) {
            let empresa = localStorage.getItem('@comer-aqui/empresaUrl');
            if (!empresa) {
                this.props.history.push('/home');
                return;
            }
            this.props.history.push(empresa);

            // this.props.history.push(`/?nomeEmpresa=` + 'gerence');
        }
        
        else if (this.props.cart.typeorder == 1) {
            // console.log('21 = /?id=' + localStorage.getItem('@comer-aqui/authorization'))
            //this.props.history.push('/?id=' + localStorage.getItem('@comer-aqui/authorization'));
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));
            // console.log('22 = /?id=' + localStorage.getItem('@comer-aqui/empresaUrl/authorization'))

        }
    }

    handleFinalizeOrder = async () => {
        try {
            // if (!this.props.cart.totalprice > 0) {
            //     toast.error("Nenhum item encontrado, por favor adicione item ao seu carrinho!");
            //     return;
            // }

            let { empresa } = this.state;

            // if (empresa.situacao == 0) {
            //     return;
            // }

            // Cesar - 20/07/2022
            if(localStorage.getItem('@comer-aqui/typeOrder') == 'MESA') {
                if (empresa.tpSituacaoqrcode) {

                } else {
                    if (empresa.situacao == 0) {
                        return;
                    }    
                }
            } else {
                if (empresa.situacao == 0) {
                    return;
                }
            }

            let { cart } = this.props;

            if(localStorage.getItem('@comer-aqui/typeOrder') == 'MESA') {
                
            } else {
                if (parseFloat(cart.totalprice) < parseFloat(empresa.vlPedidominimo)) {
                    toast.error(`Soma total dos itens no carrinho abaixo do valor mínimo de ${Currency.formatterValue.format(empresa.vlPedidominimo)} aceito!`);
                    return;
                }
            }

            for (const item of cart.items) {
                if (item.borda) {
                    const borda = {
                        id: item.borda.idProduto,
                        descricao: item.borda.dsProduto,
                        valor: item.borda.vlPreco
                    }

                    delete item['borda'];
                    item.product.borda = borda;
                }
            }            
            
            if (this.props.cart.typeorder == 4) {
                this.props.history.push('/user', { items: this.state.items });
            } 
            else 
            if (this.props.cart.typeorder == 1) {
                //( - #Gabriel
                let objEmpresa = localStorage.getItem('@comer-aqui/empresa');
                objEmpresa = JSON.parse(objEmpresa);
                let idEmpresa = objEmpresa.codEmpresa;
                let params = {
                    token: localStorage.getItem("tokenValido"),
                    codempresa: idEmpresa,
                    ...this.props.cart,
                } //)
                // localStorage.removeItem("tokenValido") 



                try {
                    await api.post(`/pedidos`, params, { //( - Gabriel - Trocado cart por params
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                } catch(erro){
                    //
                    console.log(erro)
                    toast.error("Ocorreu erro na requisição, tente novamente.");
                    this.setState({
                        disable: false,
                      });
                }

                // console.log(response)

                // console.log("teste = ", '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'))
                // this.props.history.push('/?id=' + localStorage.getItem('@comer-aqui/authorization') + '&nomeEmpresa=' + localStorage.getItem('@comer-aqui/nomeEmpresa'));

                this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));

                // Cesar - 11/10/22
                localStorage.setItem('@comer-aqui/msgToast', "Pedido finalizado com sucesso!");
                
                //localStorage.removeItem('@comer-aqui/authorization');
                //localStorage.removeItem('@comer-aqui/numberOrder');

                // toast.error("Ocorreu erro na requisição");

                this.props.checkOut();
            }
        } catch (error) {
            // console.log(error)
        }
    }

    handleFinalizeOrderByTable = () => {
        if (this.props.cart.typeorder == 1) {
            if (this.props.cart.items.length > 0 && this.state.existInVerificaimpressora) {
                this.setState({ show: true })
            } else {
                this.setState({ show: false });
                this.handleFinalizeOrder();
            }
        } else {
            this.handleFinalizeOrder();
        }
        this.setState({
            disable: true
        })
    }

    checkVerificaimpressora = async (item, e) => {
        let items = [];

        let checked = e.target.checked;

        //( #Gabriel - idImpressora não estava sendo enviado no post
        /* for (let itemCart of this.state.items) {
            let idImpressora = null;

            if (itemCart.idImpressora) {
                idImpressora = itemCart.idImpressora;
            }

            if (itemCart.id == item.id) {
                if (checked) {
                    idImpressora = item.inVerificaimpressora;
                } else {
                    idImpressora = null;
                }
            }
            items.push({ ...itemCart, idImpressora });
        } */
        
        for (let itemCart of this.props.cart.items) {
            if (itemCart.id == item.id) {
                if (checked) {
                    itemCart.idImpressora = item.inVerificaimpressora;
                } else {
                    itemCart.idImpressora = null;
                }
            }            
        }//) 

        this.setState({ items });
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
    }

    componentDidMount() {
        window.onpopstate = this.onBackButtonEvent;
        
        let existInVerificaimpressora  = false;
        
        //(Gabriel - 24/06/2022
        let existProdutosConvencionais = false;

        for (let item of this.props.cart.items) {
            if (!item.inVerificaimpressora) {
                existProdutosConvencionais = true;
            }
        }//)
        
        // console.log("props", this.props);

        //Gabriel - 24/06/2022 - Adicionado if para validar se é mesa e se existem produtos que não são bebidas
        if ((localStorage.getItem('@comer-aqui/typeOrder') == 'MESA') && (existProdutosConvencionais)) {
            for (let item of this.props.cart.items) {
                if (item.inVerificaimpressora) {
                    existInVerificaimpressora = true;
                }
            }
        }

        // console.log("typeOrder", localStorage.getItem('@comer-aqui/typeOrder'));
        // console.log("existInVerificaimpressora", existInVerificaimpressora);

        this.setState({ existInVerificaimpressora });
    }

    render() {
        let { taxaDeServico } = this.state;

        let existInVerificaimpressora = false;

        for (let item of this.props.cart.items) {
            if (item.inVerificaimpressora) {
                existInVerificaimpressora = true;
            }
        }

        return (
            <>
                <Header />
                <Popup
                    open={this.state.show}
                    modal
                    contentStyle={{
                        maxWidth: "100%",
                        width: "90%"
                    }}
                >
                    <div className="py-3 row m-0">
                        <div className="col-12">
                            <h6 className='m-0'>Selecione o(s) produto(s) que deseja receber agora na mesa</h6>
                        </div>
                        <div className='col-md-12 p-0 mt-2 mb-3'>
                            <ul className="list-group">
                                {
                                    this.props.cart.items.length > 0 && existInVerificaimpressora ?
                                        this.props.cart.items.map(item => (
                                            item.inVerificaimpressora &&
                                            <div key={`${item.id}-${item.product.id}`}>
                                                <div className="row m-0">
                                                    <div className="col-md-12">
                                                        <label className="d-flex align-items-center label-add-complement" htmlFor={item.product.id}>
                                                            <div className="check">
                                                                <input
                                                                    className="form-check-input-product"
                                                                    type="checkbox"
                                                                    id={item.product.id}
                                                                    data-id={item.product.id}
                                                                    name={item.product.id}
                                                                    checked={item.product.idImpressora}
                                                                    onClick={(e) => { this.checkVerificaimpressora(item, e); }} 
                                                                    style={{ marginTop: '2px' }}
                                                                />
                                                                <span className="ml-2" htmlFor={item.product.id}>
                                                                    {item.product.quantity > 0 && <span className="item-quantity">{item.product.quantity}</span>}
                                                                    {item.product.quantity > 0 && <span> x </span>}
                                                                    <span className="item-description">{item.product.dename}</span>
                                                                    {item.product.name}
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                        <span className="title-complement-not-found"> Nenhum produto adicionado :( </span>
                                }
                            </ul>
                        </div>
                        <div className="col-md-12 col-xl-12 col-sm-12 col-12 text-center">
                            <button className="btn btn-circle btn-block btn-danger" onClick={() => this.setState({ show: false })}>CANCELAR <FaTimesCircle /></button>
                            <button className="btn btn-block btn-success mt-2" onClick={() => this.handleFinalizeOrder(this)}>FINALIZAR PEDIDO <FaCheck /></button>
                        </div>
                    </div>
                </Popup>
                <div id="container-cart">
                    <div className="order-title">
                        <span className="title-order-preview">Resumo do pedido</span>
                    </div>
                    <div className="order-preview">
                        <div className={`order-products ${!this.props.cart.items.length > 0 && 'd-flex align-items-center h-100'}`}>
                            {
                                this.props.cart.items.length > 0 ?
                                    this.props.cart.items.map((item, key) => (
                                        <ItemCart key={key} item={item} />
                                    ))
                                    :
                                    <h6 className="text-center">Nenhum item adicionado ao carrinho :(</h6>
                            }
                        </div>
                    </div>
                    <div className="fixed-bottom action-bottom" height="auto">
                        <div className="more-items">
                            <button onClick={this.handleMoreItems}>Escolher mais <FaPlus /></button>
                        </div>
                        <div className="container order-totals" style={{ marginTop: '4px', marginBottom: '4px' }}>
                            {taxaDeServico > 0 && 
                            <><span className="title-totals">Totais</span>
                            <div className="wrapp-service-value">
                                <span className="title-service-value">Taxa de serviço</span>
                                <span className="service-value">{Currency.formatterValue.format(taxaDeServico)}*</span>
                            </div></>}
                            <div className="wrapp-amount">
                                <span className="title-amount">Total</span>
                                <span className="amount">
                                    {Currency.formatterValue.format(parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico))}
                                </span>
                            </div>
                        </div>
                        <div className="actions">
                            <button onClick={this.handleFinalizeOrderByTable} disabled={this.state.disable}>Finalizar pedido <FaCheck /></button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({ cart: state.cart });

const mapDispatchToProps = { ...CartCreator, ...ProductCreator }

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
