import React from 'react';

import './styles.css';

import Currency from '../../utils/currency';

export default function ItemComplement(props) {
    
    return (
        <li className="list-group-item">
            <label className="label-add-complement" htmlFor={props.name}>
                <div className="form-check check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={props.name}
                        name={props.name}
                        checked={props.checked}
                        onChange={(e) => { props.onChecked(e, props.price, props.id) }} />
                    <label className="form-check-label" htmlFor={props.name}>
                        {props.name}
                    </label>
                </div>
                <div className="price">
                    {props.price >= 0 &&
                        <span>{Currency.formatterValue.format(props.price)}</span>
                    }
                </div>
            </label>
        </li>
    );
}