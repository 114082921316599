import React, { Component } from 'react';

import './styles.css';

import { FaInfoCircle } from 'react-icons/fa';

import MaisInfoModal from '../MaisInfoModal/index';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            empresa: false,
            modalVisible: false,
        }
    }

    componentDidMount() {
        let empresa = localStorage.getItem("@comer-aqui/empresa")

        if (empresa) {
            this.setState({ empresa: JSON.parse(empresa) })
        }
    }

    handleShowModal = () => {
        this.setState({ modalVisible: !this.state.modalVisible }, () => { });
    }

    render() {

        let { props } = this;

        let { empresa, modalVisible } = this.state;

        return (
            <header className="row" id="main-header">
                {
                    empresa.logo &&
                    <div className="logo col-md-2 col-xl-2 col-sm-2 col-2">
                        <img src={`data:image/jpeg;base64,${empresa.logo}`} alt={empresa.fantasia} />
                    </div>
                }
                <div className={`empresa ${empresa.logo ? 'col-md-10 col-xl-10 col-sm-10 col-10' : 'col-md-12 col-xl-12 col-sm-12 col-12'}`}>
                    <div className="dados-empresa">                        
                        <div className="fantasia">{empresa.fantasia}</div>
                        {empresa.endereco && empresa.bairro && empresa.nrLogradouro && empresa.cep && (
                        <p className="endereco">
                            <span>{empresa.endereco},</span>
                            <span>{empresa.bairro} -</span>
                            <span>{empresa.nrLogradouro},</span>
                            <span>{empresa.cep}</span>
                        </p>
                        )}
                    </div>
                    <div className="mais-info"  style={{ cursor: 'pointer' }}>
                        <MaisInfoModal empresa={empresa} show={modalVisible} onClickClose={() => this.handleShowModal()} />
                        <span onClick={() => this.handleShowModal()}><FaInfoCircle className="mr-1 info-icon" /> <span className='d-none d-lg-inline-block'>Mais info</span></span>
                    </div>
                </div>

                {empresa.dsMensageminicial &&
                    <div className="col-12 px-0 py-1 dsMensageminicial">
                        <h6 className="titulo mb-0">{empresa.dsMensageminicial}</h6>
                    </div>
                }
            </header>
        );
    }
}

export default Header;