import React, { Component } from 'react';

import ReactDOM from 'react-dom';

import { FaTimes } from 'react-icons/fa';

import './styles.css';

import iconComerAqui from '../../assets/image/icon.png'

class ImageModal extends Component {
  constructor(props) {
    super(props);

    this.state = { errored: false }
  }

  onError() {
    if (!this.state.errored) {
      this.setState({ errored: true });
    }
  }


  render() {
    if (!this.props.show) {
      return null;
    }

    const { url } = this.props;

    const { errored } = this.state


    return ReactDOM.createPortal(
      <div className="ui-modal-overlay">

        <div className="ui-modal">
          <div className="row p-2">
            <div className="col-6"></div>
            <div className="col-6 text-right">
              <button className="btn btn-sm btn-dark" type="button" onClick={this.props.onClickClose}>
                <FaTimes /> FECHAR
              </button>
            </div>
            <div className="col-12 ui-modal-imagem">
              {errored || !url ?
                <img src={iconComerAqui} alt='Comer Aqui' className='img-fluid p-2' style={{ opacity: .35, height: 256, objectFit: 'contain' }} />
                :
                <img src={url} alt="Imagem do produto" className="modal-produto-imagem p-2" onError={this.onError.bind(this)} />
              }
            </div>
          </div>
        </div>
      </div>
      , document.getElementById('portal-root'))
  }
}

export default ImageModal;